:root{
  --main-gradient: linear-gradient(0deg, #18305e, #365083);
  --light-gradient: linear-gradient(0deg, #962734
  , #FC4A60);

}
.highlights marquee p {
  display: inline-block;
  text-transform: uppercase;
  margin: 0px 10px;
}
.highlights marquee p i {
  color: #000;
}
.lazyload-wrapper{
  display: unset;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--main-gradient);
  height: 105px;
}
.campuslogo {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 99;
}

.highlights {
  background: #ffffff94;
  width: 95%;
  padding-top: 14px;
}
.highlights p{
  margin-bottom: 0;
}
.logo {
  width: 30%;
  padding: 6px;
}

.header-nav {
    width: 69%;
}

nav.navbar {
  background: #fffffff2;
  padding: 15px;
  width: 95%;
  border-radius: 30px 30px 30px 3px;
  float: left;
  margin-top: 11px;
  position: relative;
  z-index: 9;
}

nav.navbar ul.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

nav.navbar ul.navbar-nav li {text-transform: uppercase;}
.button{
  background: var(--main-gradient);
  color: #fff;
}

.aboutmidsec {
  display: flex;
  justify-content: space-between;
  margin-top: -40px;
  z-index: 99;
  position: relative;
}
.about-img {
  padding: 50px 0;
}
.about-img .d-flex img:nth-child(1) {
  margin-top: 0px;
  animation: 2s bounce infinite alternate;
  position: relative;
}
@keyframes bounce{
  0%{
      margin-top: 0px
  }
  100%{
      margin-top: 10px
  }
}
.about-img .d-flex img:nth-child(3) {
  position: relative;
  top: 0px;
  animation: 2s bounce infinite alternate;
}
@keyframes bounce{
  0%{
      top: 10px
  }
  100%{
      top: 0px
  }
}
.about-img .d-flex:nth-child(2) img:nth-child(1) {
  animation: 2s scale infinite alternate;
}
.about-img .d-flex img:nth-child(2) {
  margin-top: 0px;
  animation: 3s scale infinite alternate;
}
@keyframes scale{
  0%{
      transform: scale(1);
  }
  100%{
    transform: scale(1.2);
  }
}

img.no-anim { animation: unset !important}
.about-img img {
  max-width: 80%;
}
.button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  width: 14%;
  border-radius: 6px;
  box-shadow: -2px 14px 11px -9px #0000008a;
  border: 0px;
  text-transform: uppercase;
  min-width: 120px;
  cursor: pointer;
  transition:0.5s
}
.button:hover {background: var(--light-gradient);box-shadow: -2px 14px 11px -6px #0000008a;}

.aboutmidsec .button p {
  margin: 0;
}

.aboutmidsec .button img {
  margin-right: 19px;
}
.aboutmidsec .phone {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 13px 16px;
  justify-content: center;
  box-shadow: inset 0px 0px 13px 0px #ddd;
  border-radius: 21px;
}

.about-bg {
  background: url(https://webapi.entab.info/api/image/LFSGV/public/Images/about-bg.png);
  background-size: 100% 100%;
}

.aboutsec{
  background: url(https://webapi.entab.info/api/image/LFSGV/public/Images/about.jpg);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right top;
  margin-top: -29px;
}
.newsbg {
  color: #fff;
  width: 63%;
}

.newsbg li {
  list-style: none;
  margin-bottom: 13px;
}

.newsbg  ul.newsboxlist {   
  padding: 0px;
}

.newsbg ul.newsboxlist .newsbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newsbg ul.newsboxlist .newsbox a {
  background: #fff;
  color: #000;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.newsdesc {
  width: 90%;
}

.newsbg ul.newsboxlist .newsbox p {
  font-weight: 300;
}

.newsbg marquee {
  height: 460px;
}
.about-news {
  width: 80%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 80px;
}

.title {
  color: #fff;
  text-transform: uppercase;
}

.title p {
  margin-bottom: 0;
}

.title h2 {
  position: relative;
  width: max-content;
  max-width: 100%;
  font-size: 28px;
  line-height: 49px;
}

.title h2:before {
  content: "";
  width: 100%;
  height: 3px;
  background: #fff;
  position: absolute;
  top: 120%;
  left: 0;
}
button.button.light {
  background: var(--light-gradient);
}
.about-news .button{
  margin: auto;
}.about-img .d-flex {
  align-items: center;
  justify-content: space-evenly;
  min-height: 240px;
}
.about-img .d-flex:nth-child(2) {
  min-height: 290px;
} 

.section-title .title {
  color: #962734;
  width: max-content;
  margin: auto;
  text-align: center;
  margin-bottom: 40px;
  max-width: 100%; 
}
.section-title .title h2 {
  margin: auto;
}
.section-title .title p {
  color: #666666;
}

.section-title .title h2:before {
  background: var(--main-gradient);
}

.abt-content {
  box-shadow: 0px 0px 18px -10px #060606;
  padding: 50px;
  border-radius: 65px;
  background: #fff;
  width: 70%;
  margin: 100px auto;
}

.abt-content button.button {
  margin: auto;
}

.abt-content:before {
  content: "";
  width: 100%;
  height: 90%;
  position: absolute;
  background: url(https://webapi.entab.info/api/image/LFSGV/public/Images/bg-shape.png); 
  top: 0;
  right: 0;
  z-index: -1;
  background-size: 100% 100%;
}

.abt-content-block {
  position: relative;
  overflow: hidden;
}

.event{
  background: url(https://webapi.entab.info/api/image/LFSGV/public/Images/event-bg.png);
  background-size: 100% auto;
  padding: 100px 0;
  background-repeat: no-repeat;
} 

.homeeventbox {
    width: 66%;
    margin: auto;
    background: #fff;
    border-radius: 45px;
    overflow: hidden;
    border: 1px solid #E7E7E7;
    transition: 0.5s;
    margin-top: 20px;
    margin-bottom: 10px;
    
}
.homeeventbox:hover { margin-top: 0px;}
.homeeventboxdesc {
    display: flex;
}

.homeeventboxdesc .homeeventboxbottom span {writing-mode: vertical-rl;transform: rotateZ(180deg);background: var(--light-gradient);padding: 30px 6px 22px;color: #fff;}

.homeeventboxtitle p {
    margin: 0;
    font-size: 18px;
}

.homeeventboxtitle {
    width: 100%;
    padding: 0px 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.homeeventboxtitle span {
    display: inline-block;
    color: #999999;
    margin-top: 0;
}

.homeeventboxtitle span img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.eventbg {
  margin-top: 30px;
}
button.button {
  width: max-content;
  padding: 10px 30px;    margin: 40px auto;
}
.topperdiv{
  background: url(https://webapi.entab.info/api/image/LFSGV/public/Images/topper-bg.png);
  background-size: 100% 100%;
  padding: 30px;
  width: 66%;
  margin: auto;
  overflow: hidden;
  border-radius: 59px;
  position: relative;
  min-height: 327px;
}
.birthday{
  
  background: url(https://webapi.entab.info/api/image/LFSGV/public/Images/birthday-bg.png);
  background-size: 100% 100%;
} 

.topperdiv .toppertaj img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: auto;
}

.toppertaj {
    margin-bottom: 30px;
    position: relative;
}

.topperdesc p {
    text-align: center;
    font-size: 19px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.topperdesc p span {
    display: block;
    font-weight: 300;
}

.topperdiv:before {
    content: "";
    width: 100%;
    height:100%;
    background: #000000ad;
    position: absolute;
    left: 0;
    top: 0;
}

.topperdesc {position: relative;}
.management .container-fluid{
  background: url(https://webapi.entab.info/api/image/LFSGV/public/Images/management.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.management .section-title .title p,.management .section-title .title h2 {
  color: #fff;
}

.management .section-title .title h2:before {
  background: #fff;
}

.management .section-title .title {
  margin-bottom: 80px;
}
.management-list {
  width: 66%;
  margin: auto;
  background: #fff;
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 20px 12px -20px #000;
  margin-top: 20px;
  transition: 0.5s;
}
.management-list:hover {
  margin-top: 0;
  border: 2px solid #962734;
}
.management-list img{
  width: 100%;
  margin-bottom: 20px;
}
.management-list img + p{
  min-height: 49px;
  margin-bottom: 0;
}
.management .title{
  margin-top: 40px;
}
.management-list p:first-child {
  min-height: 60px;
}

.management {
  margin-top: 100px;
}

.management-list p span {
 color: #962734;
 text-transform: uppercase;
 font-size: 82%;
}

.galleryimgbox {
  width: 80%;
  position: relative;
  margin: auto;
  border-radius: 7px;
  overflow: hidden;
}
.galleryimg:hover {
  transform: scale(1.2);
}

.galleryimg {
  margin: 40px 0;
  transition: 0.5s;
}

.galleryimgbox .gallerydesc {
  width: 93%;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #00000075;
  height: 100%;
  transform: translate(-50%, -50%);
  height: 89%;
  border-radius: 7px;
  display: flex;
  align-items: center;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  transition: 0.5s;
  opacity: 0;
}
.galleryimgbox .gallerydesc i {  color: #fff;  font-size: 40px;  margin-bottom: 20px;  display: block;  transition: 2s;  opacity: 0;}
.galleryimgbox:hover .gallerydesc i {opacity: 1}
.galleryimgbox .gallerydesc h6 {font-size: 21px;font-weight: 300;letter-spacing: 2px;text-align: center;}
.galleryimgbox:hover .gallerydesc{opacity: 1;}
.homegallery{
  background: url(https://webapi.entab.info/api/image/LFSGV/public/Images/gallery-bg.png);
  background-size: 100% auto;
  background-position: center;
  padding: 130px 0 0;
  background-repeat: no-repeat;
}
.galleryimgbox img{
  width: 100%;
  height: 230px;
  object-fit: contain;
  background: #ddd;
}

.ftr-logo ul {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  justify-content: center;
}

.ftr-logo {
  width: max-content;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}

.ftr-logo ul li {
  margin: 0px 5px;
}

.mobile-app {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 80%;
  margin-left: auto;
}
 
.mobile-app > div {
  margin-right: 40px;
}
.mobile-app p {
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
} 

.mobile-app  p.code {
  background: linear-gradient(0deg, #FFF5A7, #ffb545);
  padding: 3px 10px;
  font-size: 16px;
  border-radius: 5px;
  max-width: 360px;
  text-transform: capitalize;
  text-align: left;
}

.mobile-app .mobile-link {
  display: flex;
}

.mobile-app .mobile-link img {
  width: 144px;
  margin:  5px 5px 11px 0px;
}
.padded-sec {
  padding-left: 120px !important;
  padding-right: 120px !important;
}
.copyright{
  background: var(--main-gradient);
  text-align: center;
  font-size: 13px;
  padding: 5px 0;
  color: #fff;
}
.footer {
  padding-bottom: 30px;
}
.tooper-birthday .col-lg-4{
  position: relative;
}
.arrrow-btn {
  position: absolute;
  right: 18%;
  top: 6%;
  background: var(--main-gradient);
  color: #fff;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  line-height: 0;
  justify-content: center;
  border-radius: 4px;
  box-shadow: -2px 11px 9px -10px #000;
}

/* Inner Page */
.breadcrumb{
  background: url(https://webapi.entab.info/api/image/LFSGV/public/Images/breadcrumb.jpg);
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-position: center;
}
.breadcrumb h2 {
  margin-right: 50px;
  text-transform: uppercase;
  font-size: 5rem;
  max-width: 30%;
  color: #1e3766;
  text-shadow: -1px 3px 3px #ffffff61;
  text-align: right;
}
.breadcrumb:before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(321deg, #18305e 30%, #18305e00);
  position: absolute;
  left: 0;
  top: 0;
}

.breadcrumb h2, .breadcrumb ul {
  position: relative;
}

.breadcrumb ul {
  display: flex;
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -0%); 
  background: #fff;
  padding: 9px 10px;
  margin-bottom: 0 !important;
  border-radius: 5px 5px 0 0;
  text-transform: uppercase;
}

.breadcrumb ul li:nth-child(2) {
  border: 0px solid #000;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.breadcrumb ul li {
  padding: 0px 10px;
}
.innerSection {
  padding: 60px 0;
}
a{
  text-decoration: none;
  color: inherit;
}
a:hover{color: unset;}
h3.inner_title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 35px;
  color: #18305e;
}
img.img-fluid.border_img {
  border: 11px solid #fff;
  box-shadow: 0px 0px 6px 0px #ddd;
  margin-top: 20px;
}
.col-lg-7.overlap {
  background: #f3f3f3;
  margin-left: -60px;
  height: max-content;
  margin-top: 90px;
  box-shadow: 0px 40px 20px -40px #000;
  padding: 30px;
  border-radius: 5px;
}
.row.infra_blk {
  border: 1px dashed #ddd;
  margin-bottom: 20px;
  padding: 20px;
}

.row.infra_blk .sub_title {
  color: #962734;
  font-size: 19px;
  text-transform: uppercase;
}

.row.infra_blk img.img-fluid.border_img {
  margin-top: 0;
}
.result_blk {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: var(--light-gradient);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  border: 5px solid #fff;
  border: 11px solid #fff;
  box-shadow: 0px 0px 6px 0px #ddd;
  /* margin-top: 20px; */
  text-transform: uppercase;
}

.innerSection.result-img img {
  margin-top: 0;
}
.uniform {
  height: 90%;
  background: #ebebeb;
  margin-top: 10px;
  padding: 20px;
  border: 1px dashed #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.uniform p span {
  font-size: 18px;
  color: #d9ba28;
  font-weight: 500;
  text-transform: uppercase;
}
.clr-20{
  clear: both;
  min-height: 20px;
}
h3.sub_title {
  color: #962734;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1px;
}
.incomimg {
  width: 100%;
}
div#accordion .panel {
  margin-bottom: 40px;
}
.mt-0{
  margin-top: 0;
}
form.tc input {
  width: 100%;
  margin-bottom: 20px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

form.tc input[type="submit"] {
  width: max-content;
  padding: 5px 20px;
  background: var(--light-gradient);
  color: #fff;
}
.innerSection.contact i {
  margin-right: 15px;
  color: #962734;
}

.innerSection.contact p {
  background: #f1f1f1;
  padding: 25px;
  border-radius: 5px;
  border-left: 5px solid #962734;
  margin-top: 28px;
  display: flex;
}

.formboxright {}

.formboxright {
    padding: 30px;
    background: #efefef;
    margin-left: -110px;
    display: block;
    margin-right: 0;
    width: 100%;
    margin-top: 40px;
    border-radius: 14px;
    box-shadow: -2px 4px 9px 0px #5e5e5e;
    border: 13px solid #fff;
}

.formboxright {}

.formboxright .form-group {
    margin-top: 11px;
}

.innerSection.career img {
    width: 100%;
}

.formboxright .formdiv {
    margin-bottom: 20px;
} 

.formboxright .formdiv 
 input[type="submit"] {
    background: var(--main-gradient);
} 

.formboxright input[type="submit"] {
    background: var(--main-gradient);
    border: 0px;
    padding: 8px 20px;
    border-radius: 7px;
    text-transform: uppercase;
    color: #fff;
    margin: auto;
    display: block;
}

.formboxright h3.sub_title {
    text-align: center;
} 

.row.tabs-dynamic select, .row.tabs-dynamic input {
    width: 100%;
    border: 0px;
    border-bottom: 1px solid #ffffff47;
    padding: 3px 0;
    background: transparent;
    color: #fff;
    font-size: 18px;
}
.row.tabs-dynamic + .discription {
  text-align: center;
  margin: 20px 0;
}

.row.tabs-dynamic {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  background: var(--main-gradient);
  color: #fff;
  font-weight: 400;
}

.count-val  p {
    margin-bottom: 0;
    color: #fff;
    font-size: 18px;
}

.row.tabs-dynamic select::placeholder, .row.tabs-dynamic input::placeholder {
    color: #fff;
}

.gal_new img {
    background: #efefef;
    height: 200px;
    object-fit: contain;
    width: 100%;
}

.gal_new {
    padding: 5px;
    box-shadow: 0px 0px 5px 0px #ddd;
    margin-top: 20px;
    position: relative;
}

.gal_new .gal-content h4 {
    font-size: 18px;
    color: #962734;
}

.gal_new .gal-content {
    padding: 17px 0;
    width: 91%;
    text-align: center;
    margin: auto;
}

.gal_new .gal-content p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    min-height: 48px;
}

h6.date {
    font-weight: 400;
    text-align: left;
    font-size: 13px;
    margin-bottom: 0;
}

h6.date span {
    float: right;
    font-size: 17px;
    background: #1f3767;
    color: #fff;
    padding: 5px;
    vertical-align: middle;
    border-radius: 3px;
    line-height: 0;
}

.total-img {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    background: var(--light-gradient);
    color: #fff;
    padding: 0px 10px 5px;
    border-radius: 0 5px 5px 0px;
    box-shadow: 3px 4px 5px -3px #3e3e3e;
}

.total-img i {
    font-size: 15px;
}

.total-img p {
    margin-bottom: 0;
}

.total-img p {
    min-height: auto !important;
}

/* magnifier */

.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}


/* magnifier */
.eventCount .event-blk {
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-top: 20px;
  padding: 17px;
  position: relative;
}

.eventCount .event-blk img {
  height: 300px;
  background: #ededed;
  width: 100%;
  object-fit: contain;
}

.event-blk {}

.event-blk h6.date {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  background: var(--light-gradient);
  padding: 8px 10px;
  box-shadow: 4px 5px 6px -6px #000;
}
 

.event-blk .inr-event-content {
  padding-top: 20px;
}

.event-blk .inr-event-content h3 {
  font-size: 20px;
  text-align: center;
  color: #972734;
}

.event-blk .inr-event-content {
  text-align: center;
}

.event-blk .inr-event-content p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.event-blk .inr-event-content h6.loc {
  width: 100%;
  text-align: left;
  font-size: 14px;
}

.event-blk .inr-event-content h6.loc span {
  float: right;
}
select option {
  color: #18305e;
}
.inr-news-blk {
  border-left: 6px solid #962734;
  padding: 20px;
  margin-top: 20px;
  background: #fff;
  box-shadow: 0px 20px 15px -29px #000;
  border-radius: 5px;
  transition: 0.5s
} 

.inr-news-content h6.loc {
  color: #7a7a7a;
}
.inr-news-blk:hover {
  box-shadow: 0px 1px 15px -12px #000;
}
.topper-service img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  background: #ededed;
  border-radius: 7px;
  margin-bottom: 20px;
}
.topper-service p{
  margin-bottom: 10px;
}

.topper-service {
  border: 1px solid #ddd;
  padding: 11px;
  border-radius: 7px;
  box-shadow: 0px 26px 20px -30px #000;
  margin-top: 20px;
}
.button a {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.button a {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
li.nav-item.dropdown .nav-link {
  color: #000;
  cursor: pointer;
}
ul.dropdown-menu li a {
  padding: 7px 10px;
  transition: 0.2s;
  text-transform: capitalize;
  border-bottom: 1px solid #f5f5f5;
}
ul.dropdown-menu {
  padding: 10px;
}

ul.dropdown-menu li a:hover, .dropdown-menu a.dropdown-item.active {
  background: var(--main-gradient);
  color: #fff;
}
.result_blk h3 a{
  color: #fff;
}
.innerSection + .footer {
  padding-bottom: 30px;
  background: #f1f1f1;
}
.innerSection ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  list-style: none;
}

.innerSection ul {
  padding-left: 0;
}
.innerSection ul li:before{
  content: "";
  width: 16px;
  height: 19px;
  position: absolute;
  left: 0;
  top: 3px;  
  background: url(https://webapi.entab.info/api/image/LFSGV/public/Images/right-arrow.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.homeeventbox .homeeventboximg img {
  height: 250px;
  width: 100%;
  object-fit: contain;
  background: #ddd;
}



#exampleModalToggle .carousel-item:before{
  content: '';background: #00000021;width: 100%;height: 100%;position: static;
}


#exampleModalToggle .carousel-control-prev-icon {
  filter: invert(1);
}

#exampleModalToggle .carousel-control-next-icon {
  filter: invert(1);
}

#exampleModalToggle .carousel-control-next,
#exampleModalToggle .carousel-control-prev {
  width: auto;
}

#exampleModalToggle .carousel-control-next {
  right: 0px;
}

#exampleModalToggle .modal-dialog {
  min-width: 800px;
}

#exampleModalToggle .carousel-item .modal-header {
  background: var(--light-gradient);
  padding: 10px;
}
#exampleModalToggle .carousel-item .modalcard .card-body h3 {
  text-align: center;
  background: var(--main-gradient);
  color: #fff;
  padding: 7px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 20px;
}
#exampleModalToggle .modal-dialog img {
  width: 100%;
  object-fit: contain;
  background: #f1f1f1;
}

#exampleModalToggle .carousel-item .modal-header h1 {
  padding-bottom: 0;
  text-align: center;
  font-size: 17px;
  color: #fff;
  overflow: hidden;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  line-height: 28px;
  font-weight: normal;
  margin: 0 auto;
  width: 100%;
}

#exampleModalToggle .carousel-item .modal-dialog .modal-header .btn-close {
  color: #fff;
  padding-right: 31px
}

#exampleModalToggle .carousel-item .modalcard .card-body {
  padding: 10px 10px 0px 10px;
}

#exampleModalToggle .carousel-item .modalcard .card-body p {
  overflow: hidden;
  text-align: center;
  padding-top: 10px;
  line-height: 34px;
  font-weight: 100;
  font-size: 16px;
}

#exampleModalToggle .carousel-item .modalcard {
  border: none;
  margin-bottom: 0;
}

#exampleModalToggle .carousel-item .modal-body {
  padding: 0px 1rem 20px;
}

#exampleModalToggle .modal-content {
  padding: 0 0 0px;
}
#exampleModalToggle .modal-dialog{
  max-width: 95%;
  min-width: 95%;
}
.OnlineR {
  background: var(--light-gradient);
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 99;
  padding: 5px 20px;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 18px;
  box-shadow: -5px 5px 13px -7px #000;
}

#exampleModalToggle .carousel-item button.btn-close:focus {
  box-shadow: none
}

#exampleModalToggle .carousel-item a.btn.btn-primary.btn-sm {
  background: #d9281f;
  border: #d9281f;
  margin: 0 10px;
  color: #fff;
}

#exampleModalToggle .carousel-item a.btn.btn-primary.btn-sm:focus {
  box-shadow: none;
  border: 1px solid #182b3c;
}
 

#exampleModalToggle .carousel-item .btn-close {
  opacity: 1;
  position: relative;
  z-index: 2;
  position: absolute;
  right: 19px;
  top: 14px;
  color: #fff !important;
  filter: invert(1);
  }

  #exampleModalToggle .modal-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
}

#exampleModalToggle .modal-bottom a {
    margin: 7px;
}
.mainsld-overlay {
  position: fixed;
  z-index: 999;
  background: url(https://webapi.entab.info/api/image/LFSGV/public/Images/sld-2.jpg);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
 
.closeLoader i{
  cursor: pointer;
  font-size: 30px ;
}

.mainsld-overlay > div {
  position: absolute;
  z-index: 9999;
}

.enter-sec-details img {
  display: block;
  margin: auto;
  max-width: 90%;
}
 
.closeLoader {
  position: fixed !important;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 999;
}

.firework-animation {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}
 
@media(max-width: 1600px){
  .padded-sec {
    padding-left: 70px !important;
    padding-right: 70px !important;
}
.abt-content { 
  padding: 30px; 
  width: 90%;
}
.homeeventbox, .topperdiv , .management-list{
  width: 80%; 
}
}
@media(max-width: 1400px){
  .highlights, nav.navbar { 
    width: 100%; 
}nav.navbar ul.navbar-nav li{
  font-size: 15px;
}
  .padded-sec{
    padding-left: 30px !important;
    padding-right: 30px !important 
  }
  .event, .management .container-fluid { 
    background-size: auto 50%;
}
  .title h2 { 
    font-size: 28px;
}
.title p {
  font-size: 14px; 
}
}
@media(max-width: 1200px){
  .management-list {
    margin-bottom: 20px;
}

.header .logo {
    width: 100%;
}

.header {
    flex-wrap: wrap;
    height: auto;
}

.header .logo img {
    margin: auto;
    display: block;
}

.header-nav {
    width: 100%;
}

.highlights {
    width: 100%;
}

nav.navbar {
    width: 100%;
    margin: 0;
}
  .aboutmidsec {
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 40px;
}

.aboutmidsec .button {
    margin-bottom: 15px;
    width: 28%;
    margin-bottom: 20px;
}

.aboutmidsec .phone {
    margin-bottom: 20px;
}
.about-news {
  width: 89%; 
}
.mobile-app > div {
  margin-right: 0;
}
.ftr-logo img{
  width: 100%;
}
.ftr-logo{
  width: 90%;
}
}
@media(max-width: 991px){
  .breadcrumb h2 { 
    max-width: 100%; 
}
  .tooper-birthday .slick-slider {
    margin-bottom: 40px;
}
.about-news { 
  margin-top: 50px;
  margin-bottom: 60px;
}
.event { 
  padding: 50px 0; 
}
button.navbar-toggler {
  float: right;
  display: block;
  margin-left: auto;
  background: #fff;
}

nav.navbar {
  background: transparent;
}

div#main_nav {
  background: #fff;
  text-align: left;
}

div#main_nav ul {
  align-items: flex-start;
  padding: 15px;
  position: static;
  background: #fff;
  border-radius: 15px;
}

div#main_nav ul li {
  padding: 9px 0;
  border-bottom: 1px dashed #ddd;
  width: 100%;
}
}

@media(max-width: 767px){
  .button {
    align-items: center;
    justify-content: center;
}
  .breadcrumb h2{
    font-size: 3rem;
  }
  .aboutmidsec .button {
    margin-bottom: 15px;
    width: 40%;
    margin-bottom: 20px;
} 
.about-img .d-flex img {
  width: 46%; 
}

.about-img .d-flex {
  flex-wrap: wrap;
}
.mobile-app {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.homeeventbox, .topperdiv, .management-list {
  width: 100%;
}
.aboutmidsec .button{
  width: 100%;
}
.aboutmidsec{

  justify-content: center;
}
.about-bg { 
  background: var(--main-gradient);
}
.newsbg{
  width: 100%;
}
.abt-content {
  padding: 20px;
  width: 100%;
}
.management-list {
  margin-bottom: 20px;
}
.padded-sec {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
}
@media(max-width: 600px){
  .about-img .d-flex {
    flex-direction: column;
}

.about-img .d-flex img {
    animation: unset !important;
    width: 80%;
}
}